// import PhotoGrid from './PhotoGrid'

// new PhotoGrid()

// const images = document.querySelectorAll('img')
// let imagesIndex = 0

// Array.from(images).forEach(element => {
//   const image = new Image()

//   image.src = element.src
//   image.onload = _ => {
//     imagesIndex += 1

//     console.log(imagesIndex, images.length)

//     if (imagesIndex === images.length) {
//       document.documentElement.classList.remove('loading')
//       document.documentElement.classList.add('loaded')
//     }
//   }
// })

import anime from 'animejs/lib/anime.es.js';

function docReady(fn) {
  if (document.readyState === "complete" || document.readyState === "interactive") {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

const initPage = () => {
  loadImages();
  initParralaxBackground();
  showBackgroundImages();
}

const loadImages = () => {
  const images = document.querySelectorAll('img')
  let imagesIndex = 0

  Array.from(images).forEach(element => {
    const image = new Image()

    image.src = element.src
    image.onload = _ => {
      imagesIndex += 1

      console.log(imagesIndex, images.length)

      if (imagesIndex === images.length) {
        document.documentElement.classList.remove('loading')
        document.documentElement.classList.add('loaded')
      }
    }
  })
}

const initParralaxBackground = () => {
  const gallery = document.querySelector('.gallery');

  const windowWidth = window.innerWidth / 5;
  const windowHeight = window.innerHeight / 5;

  window.addEventListener('mousemove', (e) => {
    const mouseX = e.clientX / windowWidth;
    const mouseY = e.clientY / windowHeight; 
    gallery.style.transform = `translate3d(-${mouseX}%, -${mouseY}%, 0)`;
  });
}

const showBackgroundImages = () => {
  anime({
    targets: '.gallery__figure ',
    opacity: [0, 1],
    duration: 10000,
    ease: 'easeOutSine',
    delay: anime.stagger(300, {grid: [4, 3], from: 8})
  });
}

docReady(() => {
  initPage();
});
